
import React, { useEffect, useState, useRef, useContext, Fragment } from 'react';
import axios from 'axios';
import { startRegistration, startAuthentication } from '@simplewebauthn/browser';
import AuthenticationContext from './Context/AuthenticationContext';
import EndpointService from '../Service/EndpointService';
import PWAContext from '../PWA/Context/PWAContext';
import InstallApp from '../PWA/InstallApp';
import { apiUrl } from '../Service/EndpointService';
import { parseUserAgent } from '../PWA/Utils';
import { useConnection } from '../Context/Connection';

async function passkeyRegistrationStart() {
    let response = await EndpointService.post('/api/passkey-registration-start', {});
    return response.data;
}

async function passkeyRegistrationEnd(registrationResponse) {
    let response = await EndpointService.post('/api/passkey-registration-end', registrationResponse);
    return response.data;
}

async function passkeyLoginStart({ email }) {
    let response = await EndpointService.post('/api/passkey-login-start', { email });
    return response.data;
}

async function passkeyLoginEnd(loginResponse) {
    let response = await EndpointService.post('/api/passkey-login-end', loginResponse);
    return response.data;
}

export default function Authentication({ children }) {

    const { connected } = useConnection();
    const [showAuthenticationMenu, setShowAuthenticationMenu] = useState(false);
    const { loginInfo, renewLoginStatus, requestApplicationReset, requestApplicationLogout, requestPasskeyReset } = useContext(AuthenticationContext);
    const { isPWA, isPWAInstallable, showPrompt, registerPWA } = useContext(PWAContext);
    const [errors, setErrors] = useState({});

    const [lastLogin, setLastLogin] = useState(undefined); // { login: true, userToken: { completeLogin: true } }
    const [skipLastLogin, setSkipLastLogin] = useState(false);
    const [loginError, setLoginError] = useState("");
    const userToken = loginInfo.userToken;// { completeLogin: true }
    const isLoggedIn = loginInfo.isLoggedIn;
    // const [is2FAuthenticated, setIs2FAuthenticated] = useState(false);
    const is2FAuthenticated = loginInfo.is2FAuthenticated;
    const [askedForPasskey, setAskedForPasskey] = useState(false);

    const passkeyRegistrationIOSREF = useRef(null);
    const [passkeyRegistrationIOS, setPasskeyRegistrationIOS] = useState(false);

    passkeyRegistrationIOSREF.current = passkeyRegistrationIOS;

    const onPasskeyRegistration = async () => {


        let registrationStart = await passkeyRegistrationStart();
        console.log("registration start", registrationStart);
        let credential = await startRegistration(registrationStart);
        console.log("credential", credential);
        let registrationResponse = await passkeyRegistrationEnd(credential);
        console.log("registration response", registrationResponse);
        console.log("registration successfull")
        setAskedForPasskey(true);
        localStorage.setItem('lastPasskeyTzl', JSON.stringify({ email: userToken.email }))
        return true;

    }

    useEffect(() => {
        renewLoginStatus();
        window.EndpointService = EndpointService;
    }, []);

    useEffect(() => {

        let skipPasskeyRegistration = localStorage.getItem('skipPasskeyRegistrationTzl');
        if (skipPasskeyRegistration) {
            setAskedForPasskey(true);
        }

        let lastLoginInfo = localStorage.getItem('lastPasskeyTzl');

        if (lastLoginInfo) {
            setLastLogin(JSON.parse(lastLoginInfo));
            setAskedForPasskey(true);
        }


    }, [])

    const onLogin = async (credentials) => {
        let loginResult = await EndpointService.post('/api/login', credentials);
        if(loginResult?.data?.error) {
            setErrors({login: loginResult?.data?.error});
        }
        console.log("loginResult", loginResult.data);
        await renewLoginStatus({ loginType: 'credentials' });
        return true;
    }

    const startPasskeyLogin = async (email) => {
        let loginStart = await passkeyLoginStart({ email });
        console.log("passkey login start", loginStart);
        let credential = await startAuthentication(loginStart);
        let loginResponse = await passkeyLoginEnd(credential);
        console.log("login response", loginResponse);
        await renewLoginStatus({ loginType: 'passkey' });
        return true;
    }

    const onPassKeyLogin = async (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);
        let email = formData.get('username');
        await startPasskeyLogin(email);
        return true;


    }

    const onSecondFactorLogin = async (credentials) => {
        let response = await EndpointService.post('/api/2fa', credentials);
        console.log("response on onSecondFactorLogin", response);
        if (response?.data?.error) {
            setErrors({ '2FA': response?.data?.error })
        } else {
            renewLoginStatus();
        }
        return true;
    }

    const cancelPasskeyRegistration = (dontAskAgain) => {
        setAskedForPasskey(true);
        if (dontAskAgain) {
            localStorage.setItem('skipPasskeyRegistrationTzl', true);
        }
    }

    const startInstallPrompt = async () => {
        let installed;
        try {
            installed = await showPrompt();
            if (installed) {
                await registerPWA();
                await renewLoginStatus();
            }
        } catch (err) {
            console.log("prompt not available");
        }

        return installed;
    }



    const offerPWAInstall = isPWA && isPWAInstallable;
    const offerPasskeyInstall = isLoggedIn && is2FAuthenticated && isPWA && !askedForPasskey;

    const menuOptions = {
        logout: isLoggedIn,
        passkey: lastLogin && !isLoggedIn && isPWA,
        reset: true
    }

    const menuActions = {
        refresh: () => { window.location.reload() },
        logout: () => requestApplicationLogout(),
        passkey: () => onPasskeyRegistration(),
        reset: () => requestApplicationReset()
    }

    const offerLastLogin = lastLogin && isPWA;

    const { browserName, deviceType, browserVersion } = parseUserAgent(navigator?.userAgent || "");

    console.log("loginInfo", loginInfo);
    console.log("isLoggedIn", isLoggedIn);

    if (!isLoggedIn || !is2FAuthenticated || offerPWAInstall || (offerPasskeyInstall && !askedForPasskey)) {
        return <div className="w-screen h-screen bg-[rgb(245,239,224)] flex flex-col items-center justify-center gap-8">
            <div className="w-72">
                <img className="w-full" src="https://u-staat-centraal.nl/wp-content/uploads/2020/10/ustaatcentraal_logo-1.svg" />
            </div>
            <div className="w-96 bg-white p-8 flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                    <h1 className="text-2xl font-bold text-center text-[#3A194A]">
                        Zorgconnect portaal <span className="text-[#666] text-xs">V 1.1.12</span>

                    </h1>
                    
                   <AuthenticationMenu menuOptions={menuOptions} menuActions={menuActions}/>
                </div>


                {!isLoggedIn && offerLastLogin && <div className="flex flex-col gap-3">
                    <button className="p-2 border border-gray-400 rounded-xl w-full" onClick={() => startPasskeyLogin(lastLogin.email)}>
                        <span className="text-gray-500">Ga verder als: </span> {lastLogin.email}

                    </button>
                    <hr />
                    {!skipLastLogin && <button className="p-2 border border-gray-400 rounded-xl w-full" onClick={() => setSkipLastLogin(() => true)}>
                        Login met een ander account
                    </button>}
                </div>}


                {!isLoggedIn && ((offerLastLogin && skipLastLogin) || !offerLastLogin) && <Login error={errors?.login} onLogin={onLogin} onPassKeyLogin={onPassKeyLogin} />}
                {isLoggedIn && !is2FAuthenticated && <SecondFactorForm error={errors?.['2FA']} onSecondFactorLogin={onSecondFactorLogin} />}
                {offerPasskeyInstall && !askedForPasskey && <PasskeyRegistrationMessage
                    passkeyRegistrationIOS={passkeyRegistrationIOS} onCancel={(dontAskAgain) => cancelPasskeyRegistration(dontAskAgain)} onPasskeyRegistration={onPasskeyRegistration} />}

            </div>
            <div className="text-sm text-center">{deviceType} - {browserName} - {browserVersion}
                    
            { !connected && <div className="text-red-500 font-bold">Let op! Geen internet verbinding</div>}
            </div>
            
        </div>;
    }



    return <Fragment>
        <InstallApp onInstall={startInstallPrompt} />
        {children};
    </Fragment>
}

function Login({ onLogin, onPassKeyLogin, error }) {

    const submitLogin = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        onLogin && onLogin({ username: data.get('username'), password: data.get('password') });
    }


    return <div>
        <form onSubmit={submitLogin} className="flex flex-col gap-3">
            {error && <div className="bg-red-500 text-white p-2"> {error} </div> || null}
            <label>
                <p className="text-lg">E-mail:</p>
                <input name="username" className="w-full border border-gray-300 p-2" />
            </label>
            <label>
                <p className="text-lg">Wachtwoord:</p>
                <input name="password" type="password" className="w-full border border-gray-300 p-2" />
            </label>

            <div className="text-right">
                <a href="https://zp.local.perfectstate.nl/password-reset" className="text-blue-500">Wachtwoord vergeten?</a>
            </div>
            <button className="w-full bg-[#3A194A] text-white p-2 font-bold">Inloggen</button>
        </form>
    </div>
}

function BackButton(props) {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6  text-white" {...props}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
    </svg>
}

function LoginMenuButton(props) {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-white" {...props}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
    </svg>
}

function XMarkButton(props) {

    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-white" {...props}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>

}

function AuthenticationMenu({ menuActions, menuOptions }) {

    const [showMenu, setShowMenu] = useState(false);

    if(!showMenu) {
        return  <button className="fixed top-2 right-2 rounded-full  p-2 cursor-pointer bg-[#3A194A]" onClick={() => setShowMenu(true)}>
        <LoginMenuButton />
    </button>
    }

    return <div className="">
        <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50" onClick={() => setShowMenu(false)}>
            <div className="fixed top-2 right-2 bg-whiterounded-lg">
                <button className="rounded-full  p-2 cursor-pointer bg-[#3A194A]" onClick={() => setShowMenu(false)}><XMarkButton /></button>
            </div>
            <div className=" w-full h-full p-4 flex items-center justify-center">
                <div className="flex flex-col gap-1">
                    <button className="px-6 py-3 text-center bg-[#3A194A] text-white rounded-t-lg" onClick={() => menuActions.refresh()}>Verversen</button>
                    { menuOptions.logout && <button className="px-6 py-3 text-center bg-[#3A194A] text-white " onClick={() => menuActions.logout()} >Uitloggen</button> || null}
                    { menuOptions.passkey && <button className="px-6 py-3 text-center bg-[#3A194A] text-white" onClick={() => menuActions.passkey()}>Passkey resetten</button> || null }
                    <button className="px-6 py-3 text-center bg-[#3A194A] text-white rounded-b-lg" onClick={() => menuActions.reset()}>Applicatie resetten</button>
                </div>
            </div>
        </div>
    </div>
}

function SecondFactorForm({ onSecondFactorLogin, error }) {

    const submitSecondFactor = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        onSecondFactorLogin && onSecondFactorLogin({ token: data.get('code'), rememberToken: data.get('remember') == "on" });
    }

    return <form onSubmit={submitSecondFactor} className="flex flex-col gap-3">

        {error && <div className="bg-red-500 text-white p-2"> {error} </div>}


        <label>
            <p className="text-lg">2FA Code:</p>
            <input name="code" className="w-full border border-gray-300 p-2" />
        </label>
        <label className="flex flex-row gap-2">
            <input name="remember" type="checkbox" />
            <p className="text-lg">

                Onthoud dit apparaat/netwerk
            </p>
        </label>
        <button type="submit" className="w-full bg-[#3A194A] text-white p-2 font-bold">Inloggen afronden</button>
    </form>

}

function PasskeyRegistrationMessage({ onPasskeyRegistration, onCancel, passkeyRegistrationIOS }) {

    const [dontAskAgain, setDontAskAgain] = useState(false);
    const registrationButton = useRef(null);
    const continueButton = useRef(null);
    const finishButton = useRef(null);

    useEffect(() => {
        let skipPasskeyRegistration = localStorage.getItem('skipPasskeyRegistrationTzl');
        if (skipPasskeyRegistration) {
            setDontAskAgain(true);
        }

        registrationButton.current.addEventListener('click', async () => {

            await onPasskeyRegistration();
            return true;
        });

    }, [])


    const iosFirstCondition = !passkeyRegistrationIOS;


    return <div className="flex flex-col gap-3">
        <p className="text-lg">Inloggen met passkey</p>
        <p>
            Log vanaf nu in zoals je gewend bent, met gezichtsherkenning, vingerafdruk of code
        </p>

        <button className="p-2 bg-blue-500 text-white disabled:bg-red-500" disabled={!iosFirstCondition} ref={registrationButton}>Ja, stel in</button>
        <button className="p-2 bg-red-500 text-white" onClick={() => onCancel(dontAskAgain)}>Nee, niet nu</button>
        <label className="flex flex-row gap-3 p-2 border" onClick={(e) => setDontAskAgain((x) => !x)}>
            <input type="checkbox" value={dontAskAgain} />
            <span className="">In de toekomst niet meer vragen</span>
        </label>
    </div>
}

